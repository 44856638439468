import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import styled from '@emotion/styled';
import FunctinosTemplate from 'components/Templates/FunctinosTemplate';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface userProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgInflow: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    imgContents: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const user = ({ location, data }: userProps) => {
  const title = 'VODA | 기능 | 유입경로 분석';
  const desc =
    '해당 사이트에 사용자들이 어떠한 방법으로 유입되었는지 알려드립니다.';

  return (
    <Component>
      <Helmet>
        <title>VODA | 기능 | 유입경로 분석</title>
        <meta
          name="description"
          content="방문한 사용자의 환경을 세세하게 분석해 사용자의 관점을 이해하도록 도와드립니다"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/functions/environmentAnalysis>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <meta
          name="naver-site-verification"
          content="062e2e63e40411827d007a80f9f6ca2b16af6702"
        />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <FunctinosTemplate
        location={location}
        title={'문구\\n필요'}
        header={{
          main: '사용자들이 어떤 방법으로\\n유입되었는지 분석하고\\n어떤 컨텐츠를 이용했는지\\n확인할 수 있어요',
          sub: '예시',
        }}
        isAIvory={false}
        functionData={[
          {
            IllustImg: data.imgInflow.childImageSharp.fluid,
            descData: {
              title: '방문한 사용자의\\n유입경로를\\n분석해보세요 (수정 필요)',
              list: [
                '1. 일별 유입경로 분석',
                '2. 직접, 검색, 소셜, 추천 유입에 대한 통계 분석',
                '3. 각 유입에 대한 상세 정보 제공',
              ],
            },
            engTitle: 'INFLOWPATH',
            korTitle: '유입경로 분석 (수정 필요)',
            isBackGround: true,
            isDescLeft: false,
            width: 800,
            key: '0',
          },
          {
            IllustImg: data.imgContents.childImageSharp.fluid,
            descData: {
              title: '방문한 사용자의\\n조회한 콘텐츠를\\n분석해보세요',
              list: [
                '1. 조회한 메뉴 TOP5 분석',
                '2. 조회한 게시글 TOP5 분석',
                '3. 전체 URL별 페이지뷰 분석',
              ],
            },
            engTitle: 'CONTENTS',
            korTitle: '콘텐츠 이용 분석',
            isBackGround: false,
            isDescLeft: true,
            width: 800,
            key: '1',
          },
        ]}
        img={data.topFunctionImg.childImageSharp.fluid}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
      />
    </Component>
  );
};

export default user;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-function@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgInflow: file(name: { eq: "img-inflowpath" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgContents: file(name: { eq: "img-contents" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
